import { Button, Descriptions, Input, Select } from "antd";
import { OrderParamsModel } from "../utils/building_model";
import dayjs from "dayjs";

interface IProps {
    projectId?: string;
    projectName?: string;
    orderParams: OrderParamsModel;
    callback: (newOrderParams: OrderParamsModel) => void;
    changeRoomCallback: () => void;
}

const ContractRoomView: React.FC<IProps> = ({ projectId, projectName, orderParams, callback, changeRoomCallback }) => {

    const onChangeStatus = (value: any) => {
        let newParams = { ...orderParams } as OrderParamsModel;
        if(value === 2) {
            newParams.contractTime = dayjs.utc(Date.now()).local().format('YYYY-MM-DD');
        } else {
            newParams.contractTime = undefined;
        }
        newParams.status = value;
        callback(newParams);
    }

    let room = orderParams.room;
    let roomName = room ? `${projectName}-${room?.buildingName}-${room?.unitNo}-${room?.name}` : undefined;
    return (
        <Descriptions column={2} bordered labelStyle={{ width: 150 }} style={{ margin: '24px 0' }}>
            <Descriptions.Item label='房间号' span={1}>
                <Input placeholder="请选择房源" value={orderParams?.roomName ? orderParams.roomName : roomName} disabled suffix={
                    <Button type="link" style={{ margin: 0, padding: 0 }} onClick={changeRoomCallback}>{(orderParams.roomId || orderParams.room?.id) ? '换房' : '选房'}</Button>
                } />
            </Descriptions.Item>
            <Descriptions.Item label='房间状态' span={1}>
                <Select
                    disabled={orderParams?.contractID ? true : false}
                    value={orderParams?.status}
                    options={[
                        { key: 1, value: 1, label: '认购', color: '#55bdff' },
                        { key: 2, value: 2, label: '签约', color: '#f1f1f1', disabled: orderParams.orderID ? false : true },
                    ]}
                    style={{ width: '100%' }}
                    onChange={(value) => onChangeStatus(value)} />
            </Descriptions.Item>
            <Descriptions.Item label='建筑面积(m²)' span={1}>
                <Input value={room?.buildArea} disabled />
            </Descriptions.Item>
            <Descriptions.Item label='建筑单价(元)' span={1}>
                <Input value={room?.price} disabled />
            </Descriptions.Item>
            <Descriptions.Item label='标准总价(元)' span={1}>
                <Input value={room?.totalPrice} disabled style={{ width: '100%' }} />
            </Descriptions.Item>
            <Descriptions.Item label='' span={1}>
                <div />
            </Descriptions.Item>
        </Descriptions>
    )
}

export default ContractRoomView;