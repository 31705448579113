import { Modal } from "antd";
import { BuildingModel, OrderParamsModel, RoomModel } from "../utils/building_model";
import React, { useEffect, useState } from 'react';
import { Button, message, Steps, theme } from 'antd';
import ContractRoomView from "./contract_room_view";
import { createRoomContract, createRoomOrder, fetchOrderDetail } from "../request/api";
import ContractCustomerView from "./contract_customer_view";
import ContractOrderView from "./contract_order_view";
import dayjs from "dayjs";
import RoomPanelSelectModal from "./room_panel_select_modal";


interface IProps {
    buildings?: Array<BuildingModel>;
    projectId?: string,
    open: boolean;
    room?: RoomModel;
    orderID?: string;
    onClose: () => void;
    onUpdate: () => void;
}

const ContractModal: React.FC<IProps> = ({ buildings, projectId, open, room, orderID, onClose, onUpdate }) => {
    const defaultOrderParams: OrderParamsModel = {
        status: 1,
        projectID: undefined,
        payFormId: undefined,
        cjTotalPrice: undefined,
        loanType: '',
        discountValue: undefined,
        saleUserIds: [],
        roomId: undefined,
        room: undefined,
        customers: [{
            name: '',
            mobile: '',
            cardType: '1',
            cardId: '',
            id: undefined
        }],
        orderTime: dayjs.utc(Date.now()).local().format('YYYY-MM-DD'),
        contractTime: dayjs.utc(Date.now()).local().format('YYYY-MM-DD'),
        remark: undefined,
    };
    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [orderParams, setOrderParams] = useState<OrderParamsModel>({ ...defaultOrderParams });
    // const [anotherRoomId, setAnotherRoomId] = useState<string>();
    const [roomModalOpen, setRoomModalOpen] = useState<boolean>(false);

    useEffect(() => {
        setCurrent(0);
        setOrderParams({ ...defaultOrderParams, room: room });
        if (room) {
            getOrderDetail(room.id, undefined);
        }
    }, [room])

    useEffect(() => {
        setCurrent(0);
        setOrderParams({ ...defaultOrderParams, room: room });
        if (orderID) {
            getOrderDetail(undefined, orderID);
        }
    }, [orderID]);

    useEffect(() => {
        if (!open) {
            setCurrent(0);
            setOrderParams({ ...defaultOrderParams });
        }
    }, [open])

    // useEffect(() => {
    //     setCurrent(0);
    //     if (anotherRoomId) {
    //         getOrderDetail(anotherRoomId, undefined);
    //     }
    // }, [anotherRoomId])

    const getOrderDetail = (rid?: string, orderid?: string) => {
        let params = {
            projectID: projectId,
            roomId: rid,
            orderID: orderid,
        }
        fetchOrderDetail(params).then((res: any) => {
            if (res.code === 0 && res.data && res.data.room) {
                setOrderParams({
                    ...orderParams,
                    contractID: res.data.contractID,
                    orderID: res.data.orderID,
                    status: res.data.status,
                    projectID: res.data.room.projectId,
                    orderPriceTotal: res.data.orderPriceTotal,
                    orderTime: res.data.orderTime,
                    contractTime: res.data.contractTime ? res.data.contractTime : dayjs.utc(Date.now()).local().format('YYYY-MM-DD'),
                    contractPriceTotal: res.data.contractPriceTotal,
                    saleUserIds: res.data.users ? [...res.data.users] : [],
                    roomId: rid ? rid : res.data.room.id,
                    room: res.data.room ? res.data.room : room,
                    customers: res.data.customers ? [...res.data.customers] : [],
                    remark: res.data.remark,
                    roomName: res.data.roomName,
                    payFormId: res.data.payFormId,
                    ajBankId: res.data.ajBankId,
                    ajTotal: res.data.ajTotal,
                    ajYear: res.data.ajYear,
                    gjjBankId: res.data.gjjBankId,
                    gjjTotal: res.data.gjjTotal,
                    gjjYear: res.data.gjjYear,
                    discountValue: res.data.discountValue,
                    cjTotalPrice: (res.data.status ? (res.data.status === 1 ? res.data.orderPriceTotal : res.data.contractPriceTotal) : orderParams.cjTotalPrice)
                })
            }
        })
    }

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const updateParams = (updated: OrderParamsModel) => {
        setOrderParams(updated);
    }

    const onChangeRoom = () => {
        setRoomModalOpen(true);
    }

    const projectName = buildings?.find(item => item.projectId == projectId)?.name;

    const steps = [
        {
            title: '填写房间状态',
            content: <ContractRoomView
                projectName={projectName}
                orderParams={orderParams}
                callback={updateParams}
                changeRoomCallback={onChangeRoom} />,
        },
        {
            title: '填写客户信息',
            content: <ContractCustomerView
                projectId={projectId}
                orderParams={orderParams}
                callback={updateParams} />,
        },
        {
            title: '填写交易信息',
            content: <ContractOrderView
                projectName={projectName}
                projectId={projectId}
                orderParams={orderParams}
                callback={updateParams} />,
        },
    ];
    const items = steps.map((item) => ({ key: item.title, title: item.title }));

    const contentStyle: React.CSSProperties = {
        maxHeight: '56vh',
        overflowY: 'auto',
        textAlign: 'center',
        color: token.colorTextTertiary,
        backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        marginTop: 16,
        padding: '0 12px'
    };

    const onSubmit = () => {
        let params = {
            projectID: projectId,
            payFormId: orderParams?.payFormId,
            ajBankId: orderParams.ajBankId,
            ajTotal: orderParams.ajTotal,
            ajYear: orderParams.ajYear,
            gjjBankId: orderParams.gjjBankId,
            gjjTotal: orderParams.gjjTotal,
            gjjYear: orderParams.gjjYear,
            cjTotalPrice: orderParams?.cjTotalPrice,
            loanType: '',
            orderTime: orderParams?.orderTime ? orderParams?.orderTime.split(' ')[0] : null,
            contractTime: orderParams?.contractTime ? orderParams?.contractTime.split(' ')[0] : null,
            discountValue: orderParams?.discountValue,
            saleUserIds: orderParams?.saleUserIds?.map(item => item.userId),
            roomId: orderParams.roomId ? orderParams.roomId : orderParams?.room?.id,
            customers: orderParams?.customers?.map(item => {
                return {
                    name: item.name,
                    mobile: item.mobile,
                    idType: item.cardType,
                    idNumber: item.cardId,
                    customerID: (item.id && item.id !== '-1') ? item.id : ''
                }
            }),
            remark: orderParams.remark,
            id: '',
        }
        setLoading(true);
        if (orderParams.contractID || orderParams.status == 2) {
            params['id'] = orderParams.contractID ?? '';
            (params as any)['orderID'] = orderParams.orderID;
            createRoomContract(params).then((res: any) => {
                if (res.code === 0) {
                    message.success('保存成功');
                    onClose();
                    onUpdate();
                } else {
                    message.error(res.message);
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {
            params['id'] = orderParams.orderID ?? '';
            params.contractTime = null;
            createRoomOrder(params).then((res: any) => {
                if (res.code === 0) {
                    message.success('保存成功');
                    onClose();
                    onUpdate();
                } else {
                    message.error(res.message);
                }
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const onRoomModalClose = () => {
        setRoomModalOpen(false);
    }

    const onRoomSelect = (room: RoomModel) => {
        setRoomModalOpen(false);
        let params = { ...orderParams, room: room, roomId: room.id, roomName: undefined };
        setOrderParams(params);
    }

    const checkDisabled = () => {
        if (current === 0) {
            if (!orderParams?.roomId && !orderParams?.room?.id) return true;
        } else if (current === 1) {
            let customers = orderParams.customers;
            if (!customers) return true;

            let isInvalid = customers.some(item => !item || !item.id);
            if (isInvalid) return true;
        } else if (current === 2) {
            if (!orderParams.discountValue) return true;
            if (!orderParams.saleUserIds || orderParams.saleUserIds.length <= 0) return true;
            if (!orderParams.payFormId) return true;
            if (orderParams.status == 1) {
                if (!orderParams.orderTime || !orderParams.cjTotalPrice) return true;
            } else if (orderParams.status == 2) {
                if (!orderParams.contractTime || !orderParams.cjTotalPrice) return true;
            }
        }
        return false;
    }

    return (
        <div>
            <Modal zIndex={1111} open={open} title='编辑交易' onCancel={onClose} maskClosable width={'80vw'} footer={null}>
                <div style={{ height: 24 }} />
                <Steps current={current} items={items} />
                <div style={contentStyle}>
                    {steps[current].content}
                </div>

                <div style={{ marginTop: 24 }}>
                    {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => next()} disabled={checkDisabled()}>
                            下一步
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" loading={loading} onClick={onSubmit} disabled={checkDisabled()}>
                            提交
                        </Button>
                    )}
                    {current > 0 && (
                        <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                            上一步
                        </Button>
                    )}
                </div>
            </Modal>
            <RoomPanelSelectModal
                buildings={buildings}
                open={roomModalOpen}
                onClose={onRoomModalClose}
                onChooseRoom={onRoomSelect} />
        </div>
    );
}
export default ContractModal;