import { Button, Card, Flex, Input, Layout, message, Popover, Select, Space, Spin, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import SiderView from "./sidebar";
import { BuildingModel, RoomModel, StaticsModel } from "../utils/building_model";
import { exportHouseData, fetchProjectBuildingRooms, fetchRoomStatics } from "../request/api";
import type { TableProps } from 'antd';
import '../css/main.css';
import { CloudDownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import ContractModal from "./contract_modal";
import RoomsView from "./rooms_view";
import { exportCsv } from "../utils";

interface IProps {
    buildings?: Array<BuildingModel>;
}

export const roomTypeOptions = [
    { key: 1, value: 1, label: '销控', color: '#55bdff' },
    { key: 2, value: 2, label: '待售', color: '#f5f5f5', fcolor: '#23231f' },
    { key: 3, value: 3, label: '认购', color: '#dd5454' },
    { key: 4, value: 4, label: '签约', color: '#a151f1' },
    { key: 5, value: 5, label: '预销控', color: '#598dff' },
];

const HousesView: React.FC<IProps> = ({ buildings }) => {
    const [projectId, setProjectId] = useState<string>();
    const [buildingId, setBuildingId] = useState<number>();
    const [rooms, setRooms] = useState<Array<RoomModel>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loading2, setLoading2] = useState<boolean>(false);
    const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(15);
    const [total, setTotal] = useState<number>();
    const [displayType, setDisplayType] = useState<number>(2);
    const [statics, setStatics] = useState<Array<StaticsModel>>([]);
    const [othersStatics, setOtherStatics] = useState<Array<{
        buildingName: string;
        children: Array<StaticsModel>;
    }>>([])
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [currRoom, setCurrRoom] = useState<RoomModel>();
    const [activeKey, setActiveKey] = useState<string>('0');

    const gridStyle: React.CSSProperties = {
        width: '33.33%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 12
    };

    const tabCols: TableProps<RoomModel>['columns'] = [
        {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            render: (text, _, index) => {
                return (pageNum - 1) * pageSize + index + 1;
            }
        },
        {
            title: '房间',
            dataIndex: 'room',
            key: 'room',
            render: (_, record) => {
                let project = buildings?.find(item => item.projectId == projectId);
                return `${project?.name}-${record.buildingName}-${record.unitNo}-${record.name}`;
            }
        },
        {
            title: '房间状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            render: (text, record) => {
                let color = roomTypeOptions.find(item => item.value === record.status)?.color;
                return <Tag color={color} style={{ color: record.status == 2 ? '#23231f' : 'white' }}>{text}</Tag>
            }
        },
        {
            title: '面积(m²)',
            dataIndex: 'area',
            key: 'area',
            render: (_, record) => {
                return (
                    <div>
                        建筑：{record.buildArea}<br />
                        套内：{record.innerArea}
                    </div>
                );
            }
        },
        {
            title: '单价(元)',
            dataIndex: 'price',
            key: 'price',
            render: (_, record) => {
                return (
                    <div>
                        建筑：{record.price}<br />
                        套内：{record.innerPrice}
                    </div>
                );
            }
        },
        {
            title: '标准总价(元)',
            dataIndex: 'totalPrice',
            key: 'totalPrice'
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                if (record.status !== 2 && record.status !== 3 && record.status !== 4) return '-';
                return (
                    <Button.Group size="small">
                        <Button type='link' onClick={() => { editOrder(record) }}>
                            {
                                (record.status === 3 || record.status === 4) ? '编辑交易' : '新增交易'
                            }
                        </Button>
                    </Button.Group>
                )
            },
        }
    ];

    const [searchParams, setSearchParams] = useState({
        state: -1,
        name: undefined,
    })
    const { Content } = Layout;

    useEffect(() => {
        if (buildings && buildings.length > 0) {
            setProjectId(buildings[0].projectId);
            setBuildingId(undefined);
            setActiveKey('0');
        }
    }, [buildings])

    useEffect(() => {
        if (projectId) {
            setRooms([])
            getBuildingRooms(pageNum, pageSize, displayType, searchParams);
            getBuildingStatics();
        }
    }, [projectId, buildingId])

    const getBuildingStatics = () => {
        let params = {
            projectID: projectId,
            buildingId: buildingId
        };
        setLoading2(true);
        fetchRoomStatics(params).then((res: any) => {
            if (res.code === 0) {
                setStatics(res.data);
            }
        }).finally(() => {
            if (buildingId) {
                setLoading2(false);
            }
        })
        if (!buildingId) {
            let children = buildings?.find(item => item.projectId === projectId)?.children;
            if (children && children.length > 0) {
                let reqArrs = children.map(item => {
                    return fetchRoomStatics({ projectID: projectId, buildingId: item.buildingsId });
                });
                Promise.all(reqArrs).then(res => {
                    let othersData = res.map((item: any, index) => {
                        return {
                            buildingName: children![index].name,
                            children: [...item.data]
                        }
                    });
                    setOtherStatics(othersData);
                }).finally(() => {
                    setLoading2(false);
                });
            }
        }
    }

    const getBuildingRooms = (page: number, size: number, display: number, search: any) => {
        setLoading(true);
        let params = {
            projectId: projectId,
            buildingId: buildingId,
            pageIndex: (display == 2 && buildingId) ? 1 : page,
            pageSize: (display == 2 && buildingId) ? 10 : size,
        };
        if (display == 1) {
            params = { ...params, ...search }
        }
        fetchProjectBuildingRooms(params).then((res: any) => {
            if (res.code === 0) {
                setRooms([...res.data].map((item: any) => {
                    let desc = '';
                    if (item.status == 1) {
                        desc = '销控';
                    } else if (item.status == 2) {
                        desc = '待售';
                    } else if (item.status == 3) {
                        desc = '认购';
                    } else if (item.status == 4) {
                        desc = '签约';
                    } else if (item.status == 5) {
                        desc = '预销控';
                    }
                    return { ...item, statusDesc: desc }
                }));
                setTotal(res.rowsCount);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const onBuildingSelect = (selected: any) => {
        if (!selected || selected.length <= 0) return;
        setPageNum(1);

        let keystr = String(selected[0]);
        setActiveKey(keystr);

        let indexArr = keystr.split('_').map(index => parseInt(index));
        setProjectId((buildings![indexArr[0]]).projectId);
        if (indexArr.length > 1) {
            setBuildingId(buildings![indexArr[0]].children[indexArr[1]].buildingsId);
        } else {
            setBuildingId(undefined);
        }
    }

    const onDisplaySelect = (selected: any) => {
        setDisplayType(selected);
        setRooms([])
        getBuildingRooms(pageNum, pageSize, selected, searchParams);
    }

    const onStatusChange = (key: string, value: any) => {
        let params = { ...searchParams };
        (params as any)[key] = value;
        setPageNum(1);
        setSearchParams(params);
        getBuildingRooms(1, pageSize, displayType, params);
    }

    const pageChange = (page: number, size: number) => {
        setPageNum(page);
        setPageSize(size);
        getBuildingRooms(page, size, displayType, searchParams)
    }

    const editOrder = (room: RoomModel) => {
        setCurrRoom(room);
        setModalOpen(true);
    }

    const exportHandle = async () => {
        setDownloadLoading(true);
        let params = {
            projectId: projectId,
            buildingId: buildingId,
            ...searchParams
        }
        try {
            let file: any = await exportHouseData(params);
            if (!file) {
                setDownloadLoading(false);
                message.error('暂无数据');
                return;
            }
            exportCsv(file, '房源数据');
            setDownloadLoading(false);
        } catch (error) {
            message.error(String(error));
            setDownloadLoading(false);
        }
    }

    const buildListView = () => {
        return (
            <div>
                <Flex justify="space-between">
                    <Flex align="center">
                        房间状态：
                        <Select
                            value={searchParams.state}
                            style={{ width: 200 }}
                            placeholder='请选择状态'
                            options={[{ key: -1, value: -1, label: '全部', color: '#55bdff' }, ...roomTypeOptions]}
                            onChange={(value) => onStatusChange('state', value)} />
                        <div style={{ width: 24 }} />
                        房间搜索：
                        <Input style={{ width: 200 }} placeholder="搜索房间" allowClear onChange={(evt) => onStatusChange('name', evt.target.value)} />
                    </Flex>
                    <Button icon={<CloudDownloadOutlined />} type="primary" onClick={exportHandle} loading={downloadLoading}>导出</Button>
                </Flex>
                <div style={{ marginTop: 16 }}>
                    <Table<RoomModel>
                        rowKey={(record) => record.id}
                        columns={tabCols}
                        dataSource={rooms}
                        size="small"
                        loading={loading}
                        pagination={{
                            current: pageNum,
                            pageSize: pageSize,
                            total: total,
                            pageSizeOptions: [15, 30, 50],
                            onChange: (page, size) => pageChange(page, size)
                        }} />
                </div>
            </div>
        );
    }

    const buildPlaneView = () => {
        if (buildingId) {
            if (loading) {
                return <Spin size="large" indicator={<LoadingOutlined />} />
            }

            let project = buildings?.find(item => item.projectId == projectId);
            return (
                <div style={{ marginTop: 16 }}>
                    <Space wrap size={[64, 16]}>
                        {
                            roomTypeOptions.map((item, index) => {
                                let data = statics.find(ele => ele.status == item.value);
                                return (
                                    <Flex align="center" key={index}>
                                        <div style={{ width: 16, height: 16, border: '1px solid #ddd', background: item.color, marginRight: 4 }} />
                                        {item.label}&nbsp;
                                        {`${data ? data.number : 0}套`}&nbsp;
                                        {`占${data ? data.proportion : 0}%`}
                                    </Flex>
                                )
                            })
                        }
                    </Space>
                    <div style={{ height: 24 }} />
                    <RoomsView buildingName={project?.name ?? ''} isChangeHouse={false} rooms={rooms} callback={editOrder} />
                    <div style={{ height: 100 }} />
                </div>
            )
        }
        return (
            <div style={{ marginTop: 16 }}>
                <Space wrap size={[64, 16]}>
                    {
                        roomTypeOptions.map((item, index) => {
                            let data = statics.find(ele => ele.status == item.value);
                            return (
                                <Flex align="center" key={index}>
                                    <div style={{ width: 16, height: 16, border: '1px solid #ddd', background: item.color, marginRight: 4 }} />
                                    {item.label}&nbsp;
                                    {`${data ? data.number : 0}套`}&nbsp;
                                    {`占${data ? data.proportion : 0}%`}
                                </Flex>
                            )
                        })
                    }
                </Space>
                <div style={{ height: 24 }} />
                {
                    othersStatics?.length > 0 &&
                    <Space wrap size={24} style={{ marginLeft: 2 }}>
                        {
                            othersStatics.map((item, index) => {
                                return (
                                    <Card title={null} key={index} bordered={false}>
                                        {
                                            roomTypeOptions.map((ch, cindex) => {
                                                let data = item.children.find(ele => ele.status == ch.value);
                                                return (
                                                    <Card.Grid hoverable={false} style={gridStyle} key={`${index}_${cindex}`}>
                                                        <div>{ch.label}</div>
                                                        <div style={{ color: ch.fcolor ?? ch.color, fontSize: 18, fontWeight: 500, marginTop: 6 }}>{data ? data.number : 0}</div>
                                                    </Card.Grid>
                                                )
                                            })
                                        }
                                        <Card.Grid style={{ ...gridStyle, fontSize: 18, fontWeight: 500, width: '100%' }}>{item.buildingName}</Card.Grid>
                                    </Card>
                                )
                            })
                        }
                    </Space>
                }
            </div>
        )
    }

    const modalOnClose = () => {
        setCurrRoom(undefined);
        setModalOpen(false);
    }

    const modalOnUpdate = () => {
        getBuildingRooms(pageNum, pageSize, displayType, searchParams);
        getBuildingStatics();
    }

    return (
        <div>
            <Layout>
                <SiderView buildings={buildings} showGroupBtn callback={onBuildingSelect} defaultExpandKey={activeKey} displayCallback={onDisplaySelect} />
                <Content style={{ maxHeight: 'calc(100vh - 56px)', overflowY: 'auto', paddingRight: 16 }}>
                    {
                        displayType == 1 ?
                            buildListView()
                            :
                            (
                                loading2 ?
                                    <Spin size='large' indicator={<LoadingOutlined />} />
                                    :
                                    buildPlaneView()
                            )
                    }
                </Content>
            </Layout>
            <ContractModal buildings={buildings} projectId={projectId} open={modalOpen} room={currRoom} onClose={modalOnClose} onUpdate={modalOnUpdate} />
        </div>
    )
}

export default HousesView;