import { Divider, Flex, Layout, Radio, Tree } from 'antd';
import { BuildingModel } from '../utils/building_model';

interface IProps {
    buildings?: Array<BuildingModel>;
    showGroupBtn: boolean;
    defaultExpandKey?: string;
    callback: (selected: any) => void;
    displayCallback?: (selected: any) => void;
}

const SiderView: React.FC<IProps> = ({ defaultExpandKey, buildings, showGroupBtn, callback, displayCallback }) => {
    const { Sider } = Layout;

    const onSelect = (value: any) => {
        callback(value);
    }

    const onSelectDisplay = (value: any) => {
        if(displayCallback) {
            displayCallback(value);
        }
    }
    return (
        <Sider style={{ background: 'transparent', padding: '0 20px', border: '1px solid #f1f1f1', marginRight: 20 }} width={276}>
            <div style={{ maxHeight: 'calc(100vh - 58px)', overflowY: 'auto' }}>
                <div style={{ height: 20 }} />
                <Flex justify='space-between' align='center' style={{height: 32}}>
                    <div>楼栋列表：</div>
                    {
                        showGroupBtn ?
                            <Radio.Group defaultValue={2} optionType="button" buttonStyle="solid" onChange={(evt) => onSelectDisplay(evt.target.value)}>
                                <Radio.Button value={1}>列表</Radio.Button>
                                <Radio.Button value={2}>平铺</Radio.Button>
                            </Radio.Group>
                            :
                            <div />
                    }
                </Flex>
                <Divider style={{margin: '12px 0'}} />
                {
                    buildings ?
                        <Tree treeData={buildings} selectedKeys={[defaultExpandKey ? defaultExpandKey : '0']} defaultExpandAll onSelect={onSelect} />
                        :
                        null
                }
                <div style={{ height: 20 }} />
            </div>
        </Sider>
    )
}

export default SiderView;