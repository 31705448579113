import { Button, Flex, Input, Layout, message, Popconfirm, Select, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import SiderView from "./sidebar";
import { BuildingModel, OrderModel, OrderParamsModel } from "../utils/building_model";
import { createRoomContract, createRoomOrder, exportOrderData, fetchOrderDetail, fetchOrders } from "../request/api";
import type { TableProps } from 'antd';
import ContractModal from "./contract_modal";
import { CloudDownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { exportCsv } from "../utils";

interface IProps {
    buildings?: Array<BuildingModel>;
}

const OrdersView: React.FC<IProps> = ({ buildings }) => {
    const [projectId, setProjectId] = useState<string>();
    const [buildingId, setBuildingId] = useState<number>();
    const [orders, setOrders] = useState<Array<OrderModel>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(15);
    const [total, setTotal] = useState<number>()
    const [currOrder, setCurrOrder] = useState<OrderModel | null>();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [activeKey, setActiveKey] = useState<string>('0');

    const orderTypeOptions = [
        { key: 1, value: 1, label: '认购', color: '#dd5454' },
        { key: 2, value: 2, label: '签约', color: '#a151f1' },
        { key: 3, value: 3, label: '作废', color: '#55bdff' },
        { key: 4, value: 4, label: '换房', color: '#f1f1f1' },
        { key: 5, value: 5, label: '退房', color: '#87d068' },
    ];
    const tabCols: TableProps<OrderModel>['columns'] = [
        {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            render: (text, _, index) => {
                return (pageNum - 1) * pageSize + index + 1;
            }
        },
        {
            title: '房间',
            dataIndex: 'room',
            key: 'room',
            render: (_, record) => {
                return record.roomName;
            }
        },
        {
            title: '客户',
            dataIndex: 'customer',
            key: 'customer',
            render: (_, record) => {
                if (record.customers && record.customers.length > 0) {
                    return (
                        <Space size={[12, 6]} wrap>
                            {
                                record.customers.map((item, index) => {
                                    return (
                                        <div key={index} style={{ fontSize: 12, color: '#23231f', textAlign: 'left' }}>
                                            <b>{item.name}</b>
                                            <div style={{ color: '#999' }}>{item.mobile}</div>
                                        </div>
                                    )
                                })
                            }
                        </Space>
                    )
                }
                return (
                    <div>
                        {record.customerName}<br />
                        {record.customerMobile}
                    </div>
                );
            }
        },
        {
            title: '置业顾问',
            dataIndex: 'counselor',
            key: 'counselor',
            render: (_, record) => {
                if (record.users && record.users.length > 0) {
                    return (
                        <Space size={[12, 6]} wrap>
                            {
                                record.users.map((item, index) => {
                                    return (
                                        <div key={index} style={{ fontSize: 12, color: '#23231f', textAlign: 'left' }}>
                                            <b>{item.name}</b>
                                            <div style={{ color: '#999' }}>{item.mobile}</div>
                                        </div>
                                    )
                                })
                            }
                        </Space>
                    )
                }
                return (
                    <div>
                        {record.userName}<br />
                        {record.userMobile ?? ''}
                    </div>
                );
            }
        },
        {
            title: '认购信息',
            dataIndex: 'order',
            key: 'order',
            render: (_, record) => {
                return (
                    <div>
                        {record.orderPriceTotal}<br />
                        <span style={{ fontSize: 12, color: '#afafaf' }}>{record.orderTime ? record.orderTime.split(' ')[0] : ''}</span>
                    </div>
                );
            }
        },
        {
            title: '签约信息',
            dataIndex: 'order',
            key: 'order',
            render: (_, record) => {
                return (
                    <div>
                        {record.contractPriceTotal}<br />
                        <span style={{ fontSize: 12, color: '#afafaf' }}>{record.contractTime ? record.contractTime.split(' ')[0] : ''}</span>
                    </div>
                );
            }
        },
        {
            title: '订单状态',
            dataIndex: 'statusDesc',
            key: 'statusDesc',
            render: (text, record) => {
                if (!text) return '';
                let color = orderTypeOptions.find(item => item.value === record.status)?.color;
                return <Tag color={color} style={{ color: record.status == 4 ? '#23231f' : 'white' }}>{text}</Tag>
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                if (record.status !== 1 && record.status !== 2) {
                    return <div />
                }
                return (
                    <Button.Group size="small">
                        <Button type='link' onClick={() => { editHandle(record) }}>编辑</Button>
                        {
                            record.status == 1 &&
                            <>
                                <Popconfirm
                                    title="订单作废"
                                    description='确定作废该订单吗？'
                                    onConfirm={() => deleteOrder(record, 3)}
                                    okText="确定"
                                    cancelText="取消" >
                                    <Button type='link' danger>作废</Button>
                                </Popconfirm>
                                <Popconfirm
                                    title="退房"
                                    description='确定退订该房源吗？'
                                    onConfirm={() => deleteOrder(record, 5)}
                                    okText="确定"
                                    cancelText="取消" >
                                    <Button type='link' danger>退房</Button>
                                </Popconfirm>
                            </>
                        }
                        {
                            record.status == 2 &&
                            <Popconfirm
                                title="重置认购"
                                description='确定重置该订单合同吗？'
                                onConfirm={() => deleteOrder(record, 2)}
                                okText="确定"
                                cancelText="取消" >
                                <Button type='link' danger>重置认购</Button>
                            </Popconfirm>
                        }
                    </Button.Group>
                )
            },
        }
    ];


    const [searchParams, setSearchParams] = useState({
        status: -1,
        keyword: undefined
    })
    const { Content } = Layout;

    useEffect(() => {
        if (buildings && buildings.length > 0) {
            setProjectId(buildings[0].projectId);
            setBuildingId(undefined);
            setActiveKey('0');
        }
    }, [buildings])

    useEffect(() => {
        if (projectId) {
            getOrders(pageNum, pageSize, searchParams);
        }
    }, [projectId, buildingId])

    const editHandle = (record: OrderModel | null) => {
        setCurrOrder(record);
        setModalOpen(true);
    }

    const deleteOrder = async (record: OrderModel, targetStatus: number) => {
        let params = {
            projectID: projectId,
            orderID: record.orderID
        }
        let res: any = await fetchOrderDetail(params);
        if (res.code !== 0 || !res.data.room) {
            message.error('该订单无相关房源');
            return;
        }
        let orderParams: OrderParamsModel = {
            contractID: res.data.contractID,
            orderID: res.data.orderID,
            status: res.data.status,
            cjTotalPrice: res.data.orderPriceTotal,
            orderTime: res.data.orderTime,
            contractTime: res.data.contractTime,
            saleUserIds: res.data.users ? [...res.data.users] : [],
            roomId: res.data.room.id,
            customers: res.data.customers ? [...res.data.customers] : [],
            remark: res.data.remark,
            roomName: res.data.roomName,
            payFormId: res.data.payFormId,
            ajBankId: res.data.ajBankId,
            ajTotal: res.data.ajTotal,
            ajYear: res.data.ajYear,
            gjjBankId: res.data.gjjBankId,
            gjjTotal: res.data.gjjTotal,
            gjjYear: res.data.gjjYear,
            discountValue: res.data.discountValue
        }

        let data = {
            projectID: projectId,
            payFormId: orderParams?.payFormId,
            ajBankId: orderParams.ajBankId,
            ajTotal: orderParams.ajTotal,
            ajYear: orderParams.ajYear,
            gjjBankId: orderParams.gjjBankId,
            gjjTotal: orderParams.gjjTotal,
            gjjYear: orderParams.gjjYear,
            cjTotalPrice: orderParams?.cjTotalPrice,
            loanType: '',
            orderTime: orderParams?.orderTime,
            contractTime: orderParams?.contractTime ?? null,
            discountValue: orderParams?.discountValue,
            saleUserIds: orderParams?.saleUserIds?.map(item => item.userId),
            roomId: record.roomId,
            customers: orderParams ? orderParams?.customers?.map(item => {
                return {
                    name: item.name,
                    mobile: item.mobile,
                    idType: item.cardType,
                    idNumber: item.cardId,
                    customerID: item.id
                }
            }) : null,
            remark: orderParams.remark,
            id: '',
            status: targetStatus,
            orderID: record.orderID
        }
        setLoading(true);
        if (record.contractID || record.status == 2) {
            data.id = record.contractID;
            data.contractTime = null;
            data.customers = null;

            createRoomContract(data).then((res: any) => {
                if (res.code === 0) {
                    message.success('已重置认购');
                    getOrders(pageNum, pageSize, searchParams);
                } else {
                    message.error(res.message);
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {
            data.id = record.orderID;
            createRoomOrder(data).then((res: any) => {
                if (res.code === 0) {
                    message.success(targetStatus === 3 ? '订单已作废' : '订单已退房');
                    getOrders(pageNum, pageSize, searchParams);
                } else {
                    message.error(res.message);
                }
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const getOrders = (page: number, size: number, search: any) => {
        setLoading(true);
        let params = {
            projectId: projectId,
            buildingId: buildingId,
            pageIndex: page,
            pageSize: size,
            ...search
        };
        fetchOrders(params).then((res: any) => {
            if (res.code === 0) {
                setOrders([...res.data].map((item: any) => {
                    let desc = '';
                    if (item.status == 1) {
                        desc = '认购';
                    } else if (item.status == 2) {
                        desc = '签约';
                    } else if (item.status == 3) {
                        desc = '作废';
                    } else if (item.status == 4) {
                        desc = '换房';
                    } else if (item.status == 5) {
                        desc = '退房';
                    } else if (item.status == 6) {
                        desc = '变更价格';
                    }
                    return { ...item, statusDesc: desc }
                }));
                setTotal(res.rowsCount);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const onBuildingSelect = (selected: any) => {
        if (!selected || selected.length <= 0) return;
        setPageNum(1);

        let keystr = String(selected[0]);
        setActiveKey(keystr);
        let indexArr = keystr.split('_').map(index => parseInt(index));
        setProjectId((buildings![indexArr[0]]).projectId);
        if (indexArr.length > 1) {
            setBuildingId(buildings![indexArr[0]].children[indexArr[1]].buildingsId);
        } else {
            setBuildingId(undefined);
        }
    }

    const onStatusChange = (key: string, value: any) => {
        let params = { ...searchParams };
        (params as any)[key] = value;
        setPageNum(1);
        setSearchParams(params);
        getOrders(1, pageSize, params);
    }

    const pageChange = (page: number, size: number) => {
        setPageNum(page);
        setPageSize(size);
        getOrders(page, size, searchParams);
    }

    const modalOnClose = () => {
        setCurrOrder(null);
        setModalOpen(false);
    }

    const modalOnUpdate = () => {
        getOrders(pageNum, pageSize, searchParams);
    }

    const exportHandle = async () => {
        setDownloadLoading(true);
        let params = {
            projectId: projectId,
            buildingId: buildingId,
            ...searchParams
        }
        if (params.status < 0) {
            delete (params as any)['status'];
        }
        try {
            let file: any = await exportOrderData(params);
            if (!file) {
                setDownloadLoading(false);
                message.error('暂无数据');
                return;
            }
            exportCsv(file, '订单数据');
            setDownloadLoading(false);
        } catch (error) {
            message.error(String(error));
            setDownloadLoading(false);
        }
    }

    return (
        <div>
            <Layout>
                <SiderView buildings={buildings} showGroupBtn={false} callback={onBuildingSelect} defaultExpandKey={activeKey} />
                <Content style={{ maxHeight: 'calc(100vh - 56px)', overflowY: 'auto' }}>
                    <Flex align="center">
                        订单状态：
                        <Select style={{ width: 180 }}
                            placeholder='请选择状态'
                            options={[
                                { key: -1, value: -1, label: '全部', color: '#dd5454' },
                                ...orderTypeOptions
                            ]}
                            defaultValue={searchParams.status}
                            onChange={(value) => onStatusChange('status', value)} />
                        <div style={{ width: 24 }} />
                        模糊搜索：
                        <Input style={{ width: 320 }} placeholder="请输入关键字" allowClear onChange={(evt) => onStatusChange('keyword', evt.target.value)} />
                        <div style={{ flex: 1 }} />
                        <Button style={{ margin: '0 12px' }} type="primary" icon={<PlusOutlined />} onClick={() => editHandle(null)}>录入交易</Button>
                        <Button style={{ marginRight: 12 }} icon={<CloudDownloadOutlined />} type="primary" onClick={exportHandle} loading={downloadLoading}>导出</Button>
                    </Flex>
                    <div style={{ marginTop: 16 }}>
                        <Table<OrderModel>
                            columns={tabCols}
                            dataSource={orders}
                            rowKey={(record) => `${record.orderID}_${record.contractID}`}
                            size="small"
                            loading={loading}
                            pagination={{
                                current: pageNum,
                                pageSize: pageSize,
                                total: total,
                                pageSizeOptions: [15, 30, 50],
                                onChange: (page, size) => pageChange(page, size)
                            }} />
                    </div>
                </Content>
            </Layout>
            <ContractModal buildings={buildings} projectId={projectId} open={modalOpen} orderID={currOrder?.orderID} onClose={modalOnClose} onUpdate={modalOnUpdate} />
        </div>
    )
}
export default OrdersView;