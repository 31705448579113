import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'

export type RequestMiddleware = (config: AxiosRequestConfig) => AxiosRequestConfig
export type ResponseMiddleware<T> = (response: AxiosResponse<T>) => AxiosResponse<T>

export class AxiosService {
    private instance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        timeout: 10000
    })

    private requestMiddleware: RequestMiddleware[] = []
    private responseMiddleware: ResponseMiddleware<any>[] = []
    public static token: string = '';

    constructor() {
        // 添加前缀/api到所有请求
        this.useRequestMiddleware((config: any) => {
            config.url = `${config.url}`
            return config
        })
    }

    public useRequestMiddleware(middleware: RequestMiddleware) {
        this.requestMiddleware.push(middleware)
    }

    public useResponseMiddleware(middleware: ResponseMiddleware<any>) {
        this.responseMiddleware.push(middleware)
    }

    public async request<T>(config: AxiosRequestConfig): Promise<T> {
        try {
            // 应用请求中间件
            for (const middleware of this.requestMiddleware) {
                config = middleware(config)

                let _rtoken = window.sessionStorage.getItem('_rtoken');
                config.headers = {
                    'token': `Bearer ${_rtoken}`,
			        'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json',
                }

                if(config.url?.includes('Export')) {
                    config.responseType = 'arraybuffer';
                    config.timeout = 60000;
                }
            }

            const response: AxiosResponse<any> = await this.instance.request(config)

            // 应用响应中间件
            for (const middleware of this.responseMiddleware) {
                response.data = middleware(response.data)
            }

            return response.data as T // 类型断言为泛型类型 T
        } catch (error) {
            // 使用 AxiosError 类型来处理 Axios 异常
            const axiosError = error as AxiosError
            if (axiosError.response) {
                // 处理响应错误
                throw new Error(
                    `Request Error: ${axiosError.response.status} - ${axiosError.response.statusText}`
                )
            } else if (axiosError.request) {
                AxiosService.token = '';
                // 处理请求错误
                throw new Error(`Request Error: No response received`)
            } else {
                // 处理其他类型的错误
                throw new Error(`Request Error: ${axiosError.message}`)
            }
        }
    }
}

var axiosInstance = new AxiosService();

// GET请求
export async function get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await axiosInstance.request<T>({ ...config, method: 'get', url })
    return response
}

// POST请求
export async function post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    const response = await axiosInstance.request<T>({ ...config, method: 'post', url, data })
    return response
}

// PUT请求
export async function put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    const response = await axiosInstance.request<T>({ ...config, method: 'put', url, data })
    return response
}

// PATCH请求
export async function patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    const response = await axiosInstance.request<T>({ ...config, method: 'patch', url, data })
    return response
}

// DELETE请求
export async function del<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await axiosInstance.request<T>({ ...config, method: 'delete', url })
    return response
}