import { useEffect, useState } from "react";
import { BuildingModel, RoomModel } from "../utils/building_model";
import { Button, Flex, Layout, Modal, Popover, Space, Spin, Tooltip } from "antd";
import SiderView from "./sidebar";
import { LoadingOutlined } from "@ant-design/icons";
import { roomTypeOptions } from "./house";
import { fetchProjectBuildingRooms } from "../request/api";
import RoomsView from "./rooms_view";

interface IProps {
    buildings?: Array<BuildingModel>;
    open: boolean;
    onClose: () => void;
    onChooseRoom: (room: RoomModel) => void;
}
const RoomPanelSelectModal: React.FC<IProps> = ({ buildings, open, onClose, onChooseRoom }) => {
    const [currBuildingId, setCurrBuildingId] = useState<number>();
    const [currProjectId, setCurrProjectId] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [rooms, setRooms] = useState<Array<RoomModel>>([]);
    const [activeKey, setActiveKey] = useState<string>();
    const { Content } = Layout;

    useEffect(() => {
        if (buildings && buildings.length > 0) {
            setCurrProjectId(buildings[0].projectId);
            let tmpBuilding = buildings.find(item => item.children && item.children.length > 0);
            if (tmpBuilding) {
                setCurrBuildingId(tmpBuilding.children[0].buildingsId);
                setActiveKey('0_0');
            } else {
                setActiveKey('0')
            }
        }
    }, [buildings]);

    useEffect(() => {
        if (currProjectId && currBuildingId) {
            getBuildingRooms()
        }
    }, [currProjectId, currBuildingId]);


    const getBuildingRooms = () => {
        setLoading(true);
        let params = {
            projectId: currProjectId,
            buildingId: currBuildingId,
            pageIndex: 1,
            pageSize: 10,
        };
        fetchProjectBuildingRooms(params).then((res: any) => {
            if (res.code === 0) {
                setRooms([...res.data].map((item: any) => {
                    let desc = '';
                    if (item.status == 1) {
                        desc = '销控';
                    } else if (item.status == 2) {
                        desc = '待售';
                    } else if (item.status == 3) {
                        desc = '认购';
                    } else if (item.status == 4) {
                        desc = '签约';
                    } else if (item.status == 5) {
                        desc = '预销控';
                    }
                    return { ...item, statusDesc: desc }
                }));
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const buildPlaneView = () => {
        if (currBuildingId) {
            if (loading) {
                return <Spin size="large" indicator={<LoadingOutlined />} />
            }
            let project = buildings?.find(item => item.projectId == currProjectId);
            return (
                <div style={{ marginTop: 16 }}>
                    <Space wrap size={[64, 16]}>
                        {
                            roomTypeOptions.map((item, index) => {
                                return (
                                    <Flex align="center" key={index}>
                                        <div style={{ width: 16, height: 16, border: '1px solid #ddd', background: item.color, marginRight: 4 }} />
                                        {item.label}
                                    </Flex>
                                )
                            })
                        }
                    </Space>
                    <div style={{ height: 24 }} />
                    <RoomsView buildingName={project?.name ?? ''} rooms={rooms} isChangeHouse={true} callback={onSelected} />
                </div>
            )
        }
    }

    const onSelected = (room: RoomModel) => {
        onChooseRoom(room);
        onClose();
    }

    const onBuildingSelect = (selected: any) => {
        if (!selected || selected.length <= 0) return;
        let keystr = String(selected[0]);
        setActiveKey(keystr);
        let indexArr = keystr.split('_').map(index => parseInt(index));
        if (indexArr.length < 2) return;
        setCurrProjectId((buildings![indexArr[0]]).projectId);
        setCurrBuildingId((buildings![indexArr[0]].children[indexArr[1]]).buildingsId);
    }

    return (
        <div>
            <Modal zIndex={1112} open={open} title='切换房源' onCancel={onClose} maskClosable width={'70vw'} footer={null}>
                <div style={{ height: 24 }} />
                <Layout>
                    <SiderView defaultExpandKey={activeKey} buildings={buildings} showGroupBtn={false} callback={onBuildingSelect} />
                    <Content style={{ maxHeight: '70vh', overflowY: 'auto', paddingRight: 16 }}>
                        {
                            loading ?
                                <Spin size='large' indicator={<LoadingOutlined />} />
                                :
                                buildPlaneView()
                        }
                    </Content>
                </Layout>
                <div style={{ height: 24 }} />
            </Modal>
        </div>
    )
}

export default RoomPanelSelectModal;