import * as AxiosRequest from "../index";

export function getProjects(params: any) {
    return AxiosRequest.post('/api/Room/GetProjectChildBuildings', { ...params })
}

export function login() {
    return AxiosRequest.post('/api/Employee/Login', { userId: "test001", password: "123456" });
}

export function fetchProjectBuildingRooms(params: any) {
    return AxiosRequest.post('/api/Room/GetProjectRooms', params);
}

export function fetchOrders(params: any) {
    return AxiosRequest.post('/api/Order/GetOrders', params);
}

export function fetchRoomStatics(params: any) {
    return AxiosRequest.post('/api/Room/GetRoomStatusStatistics', params);
}

export function fetchOrderDetail(params: any) {
    return AxiosRequest.post('/api/Order/GetOrderDetail', params);
}

export function fetchRoomDetail(params: any) {
    return AxiosRequest.post('/api/Room/GetProjectRoomDetail', params);
}

export function fetchCustomers(params: any) {
    return AxiosRequest.post('/api/Customer/GetCustomers', params);
}

export function fetchCounselors(params: any) {
    return AxiosRequest.post('/api/Employee/GetProjectUsers', params);
}

export function createRoomContract(params: any) {
    return AxiosRequest.post('/api/Room/RoomContract', params);
}

export function createRoomOrder(params: any) {
    return AxiosRequest.post('/api/Room/RoomOrder', params);
}

export function getParamsConfig(params: any) {
    return AxiosRequest.post('/api/Customer/GetBusinessPara', params);
}

export function fetchRoles(userid: any) {
    return AxiosRequest.get(`/api/Employee/GetUserRoles?userId=${userid}`);
}

export function fetchProjects(params: any) {
    return AxiosRequest.post('/api/Employee/GetUserRoleProjects', params);
}

export function loginWithToken(params: any) {
    return AxiosRequest.post('/api/Employee/GetLoginByKey', params);
}

export function exportHouseData(params: any) {
    return AxiosRequest.post('/api/Room/ExportExcelRooms', params);
}

export function exportOrderData(params: any) {
    return AxiosRequest.post('/api/Order/ExportExcelOrders', params);
}