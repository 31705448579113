import { Button, Divider, Flex, Popover, Space, Tooltip } from "antd";
import { RoomModel, StaticsModel } from "../utils/building_model";
import { roomTypeOptions } from "./house";
import { useEffect, useState } from "react";

interface IProps {
    rooms: Array<RoomModel>;
    buildingName: string;
    callback: (room: RoomModel) => void;
    isChangeHouse: boolean;
}
const RoomsView: React.FC<IProps> = ({ rooms, buildingName, isChangeHouse, callback }) => {
    const [currUnit, setCurrUnit] = useState<number>();
    const [units, setUnits] = useState<Array<number>>();
    const [unitFloors, setUnitFloors] = useState<Array<{
        floor: number;
        rooms: Array<RoomModel>;
    }>>([])
    useEffect(() => {
        let floors: Array<{
            floor: number;
            rooms: Array<RoomModel>;
        }> = [];
        let unitsData: Array<number> = [];
        rooms.forEach(room => {
            let floor = floors.find(item => item.floor == room.floorNo);
            if (floor) {
                floor.rooms.push(room);
                floor.rooms.sort((a, b) => (parseInt(a.name) - parseInt(b.name)));
            } else {
                floors.push({
                    floor: room.floorNo,
                    rooms: [room]
                })
            }
            if (!unitsData.includes(room.unitNo)) {
                unitsData.push(room.unitNo);
            }
        })
        floors.sort((a, b) => b.floor - a.floor);
        unitsData.sort((a, b) => a - b);

        setUnitFloors(floors);
        setUnits(unitsData);
        if (unitsData.length > 0) {
            setCurrUnit(unitsData[0]);
        }
    }, [rooms]);


    const editOrder = (room: RoomModel) => {
        callback(room);
    }

    const getUnitFloors = () => {
        return unitFloors.map(item => {
            return {
                ...item, rooms: item.rooms.filter(room => room.unitNo === currUnit)
            }
        })
    }

    const chooseUnit = (value: number) => {
        setCurrUnit(value);
    }

    let tmpFoors = getUnitFloors();

    // console.log(tmpFoors);

    return (
        
        <div>
            <Space>
                {
                    units?.map(item => {
                        return (
                            <Button onClick={() => chooseUnit(item)} shape="round" size="middle" key={item} type={item === currUnit ? 'primary' : 'default'}>{item}单元</Button>
                        )
                    })
                }
            </Space>
            <Divider />
            <div>
            {
                tmpFoors.map((item, index) => {
                    return (
                        <Flex key={index}>
                            <div className={`grid-floor border-3-side ${index == tmpFoors.length - 1 ? 'border-bottom' : ''}`}>{item.floor}</div>
                            {
                                item.rooms.map((room, rindex) => {
                                    let statusInfo = roomTypeOptions.find(rtype => rtype.value == room.status);
                                    let topTips = `${buildingName}-${room.buildingName}-${room.unitNo}-${room.name}`;
                                    return (
                                        <div style={{ background: statusInfo?.color, color: (statusInfo?.fcolor ?? '#fff') }} className={`grid-room ${rindex == item.rooms.length - 1 ? 'border-right' : 'border-3-side'} ${index == tmpFoors.length - 1 ? 'border-bottom' : ''}`} key={`${index}_${rindex}`}>
                                            {room.name}
                                            {
                                                isChangeHouse ?
                                                    (
                                                        (room.status === 2) &&
                                                        (
                                                            <Tooltip trigger={'hover'} placement="top" title={topTips}>
                                                                <div className="grid-poz" onClick={() => editOrder(room)} />
                                                            </Tooltip>
                                                        )
                                                    )
                                                    :
                                                    (
                                                        (room.status === 2 || room.status === 3 || room.status === 4) &&
                                                        (
                                                            <Popover placement="bottom" trigger={'click'} content={<Button size="small" type="link" onClick={() => editOrder(room)}>{(room.status == 3 || room.status == 4) ? '编辑交易' : '新增交易'}</Button>}>
                                                                <Tooltip trigger={'click'} placement="top" title={topTips}>
                                                                    <div className="grid-poz" />
                                                                </Tooltip>
                                                            </Popover>
                                                        )
                                                    )
                                            }
                                        </div>
                                    )
                                })
                            }
                        </Flex>
                    )
                })
            }
        </div>
        </div>
    )
}

export default RoomsView;