import { useEffect, useState } from "react";
import HousesView from "../components/house";
import OrdersView from "../components/order";
import { Tabs, ConfigProvider, message, Select, Space } from 'antd';
import { getProjects, loginWithToken } from "../request/api";
import { AxiosService } from "../request";
import { BuildingModel, ProjectModel } from "../utils/building_model";
import zhCN from 'antd/locale/zh_CN';
import dayjs from "dayjs";
import 'dayjs/locale/zh-cn';

dayjs.locale('zh-cn');

const IndexScreen: React.FC = () => {
    const [currProjectId, setCurrProjectId] = useState<string>();
    const [projects, setProjects] = useState<Array<ProjectModel>>([]);
    const [buildings, setBuildings] = useState<Array<BuildingModel>>();
    const tabItems = [
        {
            label: '房源信息',
            key: 'hot',
            children: <HousesView buildings={buildings} />
        },
        {
            label: '交易管理',
            key: 'new',
            children: <OrdersView  buildings={buildings} />
        }
    ]

    useEffect(() => {
        initData();
    }, [])

    const initData = async () => {
        let url = new URL(window.location.href);
        let key = url.searchParams.get('key');
        if(!key) {
            message.error('token缺失');
            return;
        }
        let info: any = await loginWithToken({key});

        if(!info.token) {
            message.error('鉴权失败，请刷新页面');
            return;
        }
        window.sessionStorage.setItem('_rtoken', info.token);

        let projects: Array<ProjectModel> = [...info.data];
        if(projects.length <= 0) {
            message.error('暂无项目信息');
            return;
        }
        let projectId = projects[0].id;
        setCurrProjectId(projectId);
        setProjects(projects);
    }

    const onProjectChange = (value: any) => {
        setCurrProjectId(value);
    }

    useEffect(() => {
        if(!currProjectId) return;
        getDatas(currProjectId);
    }, [currProjectId])

    const getDatas = async (projectId: any) => {
        let params = { projectID: projectId }
        let res:any = await getProjects(params);
        if(res.code !== 0) return;
        setBuildings(Array.from(res.data).map((item: any, index1) => {
            return {
                name: item.name,
                title: item.name,
                projectId: item.projectId,
                key: `${index1}`,
                children: Array.from(item.buildings).map((ch: any, index2) => {
                    return {
                        buildingsId: ch.buildingsId,
                        key: `${index1}_${index2}`,
                        floorNum: ch.floorNum,
                        name: ch.name,
                        title: ch.name,
                    }
                })
            }
        }));
    }

    const getExtra = () => {
        return (
            <Space>
                项目切换：
                <Select 
                value={currProjectId}
                options={projects.map(item => ({key: item.id, label: item.name, value: item.id}))}
                style={{width: 240, marginRight: 12}}
                onChange={(value) => onProjectChange(value)} />
            </Space>
        )
    }

    return (
        <ConfigProvider locale={zhCN} theme={{
            components: {
                Tabs: {
                    cardBg: 'transparent'
                },
                Layout: {
                    siderBg: 'transparent',
                    bodyBg: 'transparent'
                },
                Input: {
                    colorTextDisabled: '#23231f',
                    colorBgContainerDisabled: '#f7f7f7'
                },
                Select: {
                    colorTextDisabled: '#23231f',
                    colorBgContainerDisabled: '#f7f7f7'
                }
            }
        }}>
            <Tabs items={tabItems} type="card" tabBarExtraContent={getExtra()} />
        </ConfigProvider>
    )
}

export default IndexScreen;