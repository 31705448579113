import { DatePicker, Descriptions, Flex, Input, Select, Space, Typography } from "antd";
import { CounselorModel, OrderParamsModel } from "../utils/building_model"
import { useEffect, useState } from "react";
import { fetchCounselors, getParamsConfig } from "../request/api";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface IProps {
    projectName?: string;
    projectId?: string;
    orderParams?: OrderParamsModel;
    callback: (updated: OrderParamsModel) => void;
}

const ContractOrderView: React.FC<IProps> = ({ projectName, projectId, orderParams, callback }) => {
    const [counselorOptions, setCounselorOptions] = useState<Array<CounselorModel>>([]);
    const [payTypes, setPayTypes] = useState<Array<any>>([]);
    const [banks, setBanks] = useState<Array<any>>([]);

    useEffect(() => {
        let params = {
            'projectID': projectId,
        }
        fetchCounselors(params).then((res: any) => {
            if (res.code === 0) {
                setCounselorOptions([...res.data].map(item => {
                    return {
                        userId: item.id,
                        userName: item.name,
                        userMobile: item.mobile
                    }
                }))
            }
        });

        getPayTypes();
        getBanks();
    }, [])

    const getPayTypes = () => {
        let params = {
            projectID: projectId,
            paraType: "PayTypes"
        }
        getParamsConfig(params).then((res: any) => {
            if (res.code === 0) {
                setPayTypes(Array.from(res.payforms).map((item: any) => {
                    return {
                        ...item,
                        key: item.id,
                        label: item.name,
                        value: item.id,
                    }
                }))
            }
        })
    }

    const getBanks = () => {
        let params = {
            projectID: projectId,
            paraType: "Banks"
        }
        getParamsConfig(params).then((res: any) => {
            if (res.code === 0) {
                setBanks(Array.from(res.banks).map((item: any) => {
                    return {
                        ...item,
                        key: item.id,
                        label: item.name,
                        value: item.id,
                    }
                }))
            }
        })
    }

    const onSelectChange = (values: any) => {
        console.log(values);
        let users = (values ?? []).map((id: any) => {
            return counselorOptions.find(couselor => couselor.userId === id);
        })
        let newParams = { ...orderParams };
        newParams.saleUserIds = users;
        callback(newParams);
    }

    const onInputChange = (key: string, value: any) => {
        let newParams = { ...orderParams };
        if (key === 'contractTime' || key === 'orderTime') {
            value = dayjs.utc(value).local().format('YYYY-MM-DD');
        } else if (key === 'payFormId') {
            if (value == 1) {
                newParams.ajBankId = undefined;
                newParams.ajTotal = undefined;
                newParams.ajYear = undefined;
                newParams.gjjBankId = undefined;
                newParams.gjjTotal = undefined;
                newParams.gjjYear = undefined;
            } else if (value == 2) {
                newParams.gjjBankId = undefined;
                newParams.gjjTotal = undefined;
                newParams.gjjYear = undefined;
            } else if (value == 4) {
                newParams.ajBankId = undefined;
                newParams.ajTotal = undefined;
                newParams.ajYear = undefined;
            }
        } else if (key === 'discountValue') {
            if (value && !isNaN(value)) {
                newParams.cjTotalPrice = Math.ceil(parseFloat(orderParams?.room?.totalPrice ?? '0') * parseFloat(value ?? '0') / 100).toString();
            } else {
                newParams.cjTotalPrice = undefined;
            }
        } else if (key === 'cjTotalPrice') {
            if (value && !isNaN(value) && parseFloat(orderParams?.room?.totalPrice ?? '0') > 0) {
                newParams.discountValue = (100 * parseFloat(value ?? '0') / parseFloat(orderParams?.room?.totalPrice ?? '0')).toFixed(2);
            } else {
                newParams.discountValue = undefined;
            }
        }
        (newParams as any)[key] = value;
        callback(newParams)
    }
    let roomName = orderParams?.roomName ? orderParams.roomName : `${projectName}-${orderParams?.room?.buildingName}-${orderParams?.room?.floorNo}-${orderParams?.room?.name}`;
    return (
        <div>
            <Descriptions size="small" title={roomName} bordered column={2} labelStyle={{ width: 120 }} style={{ margin: '24px 0' }}>
                <Descriptions.Item label='建筑面积(m²)' span={1}>
                    <Input value={orderParams?.room?.buildArea} disabled />
                </Descriptions.Item>
                <Descriptions.Item label='建筑单价(元)' span={1}>
                    <Input value={orderParams?.room?.price} disabled />
                </Descriptions.Item>
                <Descriptions.Item label='标准总价(元)' span={1}>
                    <Input value={orderParams?.room?.totalPrice} disabled />
                </Descriptions.Item>
                <Descriptions.Item label={<div><span style={{color: 'red'}}>*</span>折扣(%)</div>} span={1}>
                    <Input suffix='%' value={orderParams?.discountValue} onChange={(evt) => onInputChange('discountValue', evt.target.value)} />
                </Descriptions.Item>
                {
                    orderParams?.status == 1 ?
                        <>
                            <Descriptions.Item label={<div><span style={{color: 'red'}}>*</span>认购金额(元)</div>} span={1}>
                                <Input value={orderParams?.cjTotalPrice ?? orderParams.orderPriceTotal} onChange={(evt) => onInputChange('cjTotalPrice', evt.target.value)} />
                            </Descriptions.Item>
                            <Descriptions.Item label={<div><span style={{color: 'red'}}>*</span>认购日期</div>} span={1}>
                                <DatePicker
                                    placeholder="选择日期"
                                    defaultValue={(orderParams?.orderTime) ? dayjs(orderParams?.orderTime) : ''}
                                    style={{ width: '100%' }}
                                    onChange={(evt) => onInputChange('orderTime', evt)} />
                            </Descriptions.Item>
                        </>
                        :
                        <>
                            <Descriptions.Item label='认购金额(元)' span={1}>
                                <Input disabled value={orderParams?.orderPriceTotal} onChange={(evt) => onInputChange('orderPriceTotal', evt.target.value)} />
                            </Descriptions.Item>
                            <Descriptions.Item label='认购日期' span={1}>
                                <DatePicker
                                    disabled
                                    placeholder="选择日期"
                                    defaultValue={(orderParams?.orderTime) ? dayjs(orderParams?.orderTime) : ''}
                                    style={{ width: '100%' }}
                                    onChange={(evt) => onInputChange('orderTime', evt)} />
                            </Descriptions.Item>
                            <Descriptions.Item label={<div><span style={{color: 'red'}}>*</span>签约金额(元)</div>} span={1}>
                                <Input value={orderParams?.cjTotalPrice ?? orderParams?.contractPriceTotal} onChange={(evt) => onInputChange('cjTotalPrice', evt.target.value)} />
                            </Descriptions.Item>
                            <Descriptions.Item label={<div><span style={{color: 'red'}}>*</span>签约日期</div>} span={1}>
                                <DatePicker
                                    placeholder="选择日期"
                                    defaultValue={(orderParams?.contractTime) ? dayjs(orderParams?.contractTime) : ''}
                                    style={{ width: '100%' }}
                                    onChange={(evt) => onInputChange('contractTime', evt)} />
                            </Descriptions.Item>
                        </>
                }

                <Descriptions.Item label={<div><span style={{color: 'red'}}>*</span>付款方式</div>} span={2}>
                    <Flex justify="flex-start">
                        <Select style={{ width: 200 }} options={payTypes} value={orderParams?.payFormId} onChange={(value) => onInputChange('payFormId', value)} />
                    </Flex>
                </Descriptions.Item>
                {
                    orderParams?.payFormId == 2 &&
                    <Descriptions.Item label='商业贷款' span={2}>
                        <Space wrap size={[32, 4]}>
                            <Space>
                                <div>按揭银行：</div>
                                <Select style={{ width: 180 }} options={banks} value={orderParams?.ajBankId} onChange={(value) => onInputChange('ajBankId', value)} />
                            </Space>
                            <Space style={{ margin: '10px 0' }}>
                                <div>按揭金额：</div>
                                <Input style={{ width: 180 }} value={orderParams?.ajTotal} onChange={(evt) => onInputChange('ajTotal', evt.target.value)} />
                            </Space>
                            <Space>
                                <div>按揭年限：</div>
                                <Input style={{ width: 180 }} type="number" value={orderParams?.ajYear} onChange={(evt) => onInputChange('ajYear', parseInt(evt.target.value))} />
                            </Space>
                        </Space>
                    </Descriptions.Item>
                }
                {
                    orderParams?.payFormId == 4 &&
                    <Descriptions.Item label='公积金贷款' span={2}>
                        <Space wrap size={[32, 4]}>
                            <Space>
                                <div>贷款银行：</div>
                                <Select style={{ width: 180 }} options={banks} value={orderParams?.gjjBankId} onChange={(value) => onInputChange('gjjBankId', value)} />
                            </Space>
                            <Space style={{ margin: '10px 0' }}>
                                <div>贷款金额：</div>
                                <Input style={{ width: 180 }} value={orderParams?.gjjTotal} onChange={(evt) => onInputChange('gjjTotal', evt.target.value)} />
                            </Space>
                            <Space>
                                <div>贷款年限：</div>
                                <Input style={{ width: 180 }} type="number" value={orderParams?.gjjYear} onChange={(evt) => onInputChange('gjjYear', parseInt(evt.target.value))} />
                            </Space>
                        </Space>
                    </Descriptions.Item>
                }
                {

                    orderParams?.payFormId == 3 &&
                    <>
                        <Descriptions.Item label='商业贷款' span={1}>
                            <Space wrap size={[6, 2]}>
                                <Space>
                                    <div>贷款银行：</div>
                                    <Select style={{ width: 180 }} options={banks} value={orderParams?.ajBankId} onChange={(value) => onInputChange('ajBankId', value)} />
                                </Space>
                                <Space style={{ margin: '10px 0' }}>
                                    <div>贷款金额：</div>
                                    <Input style={{ width: '100%' }} value={orderParams?.ajTotal} onChange={(evt) => onInputChange('ajTotal', evt.target.value)} />
                                </Space>
                                <Space>
                                    <div>贷款年限：</div>
                                    <Input style={{ width: '100%' }} type="number" value={orderParams?.ajYear} onChange={(evt) => onInputChange('ajYear', parseInt(evt.target.value))} />
                                </Space>
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label='公积金贷款' span={1}>
                            <Space wrap size={[6, 2]}>
                                <Space>
                                    <div>贷款银行：</div>
                                    <Select style={{ width: 180 }} options={banks} value={orderParams?.gjjBankId} onChange={(value) => onInputChange('gjjBankId', value)} />
                                </Space>
                                <Space style={{ margin: '10px 0' }}>
                                    <div>贷款金额：</div>
                                    <Input style={{ width: '100%' }} value={orderParams?.gjjTotal} onChange={(evt) => onInputChange('gjjTotal', evt.target.value)} />
                                </Space>
                                <Space>
                                    <div>贷款年限：</div>
                                    <Input style={{ width: '100%' }} type="number" value={orderParams?.gjjYear} onChange={(evt) => onInputChange('gjjYear', parseInt(evt.target.value))} />
                                </Space>
                            </Space>
                        </Descriptions.Item>
                    </>
                }
                <Descriptions.Item label='备注' span={2}>
                    <Input.TextArea value={orderParams?.remark} onChange={(evt) => onInputChange('remark', evt.target.value)} />
                </Descriptions.Item>
            </Descriptions>

            <Descriptions size="small" title='置业顾问' bordered column={2} labelStyle={{ width: 120 }} style={{ margin: '24px 0' }}>
                <Descriptions.Item label={<div><span style={{color: 'red'}}>*</span>选择顾问</div>} span={2}>
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder='选择顾问'
                        suffixIcon={null}
                        value={orderParams?.saleUserIds?.map(item => item.userId)}
                        options={counselorOptions.map(counselor => ({ key: counselor.userId, value: counselor.userId, label: counselor.userName, name: counselor.userName, mobile: counselor.userMobile }))}
                        optionRender={(option) => {
                            return (
                                <Space>
                                    <div style={{ flex: 1 }}>
                                        <b style={{ wordBreak: 'break-word' }}>{option.data.name}</b>
                                        <div>
                                            <Typography.Text style={{ color: '#999', fontSize: 12 }}>
                                                {option.data.mobile}
                                            </Typography.Text>
                                        </div>
                                    </div>
                                </Space>
                            )
                        }}
                        onChange={(value) => onSelectChange(value)}
                        allowClear
                    />
                </Descriptions.Item>
            </Descriptions>
        </div>
    );
}

export default ContractOrderView;