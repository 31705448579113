import { Button, Card, Form, Input, Select, Space, Spin, Typography } from "antd";
import { CustomerModel, OrderParamsModel } from "../utils/building_model"
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { fetchCustomers } from "../request/api";

interface IProps {
    projectId?: string;
    orderParams?: OrderParamsModel;
    callback: (updated: OrderParamsModel) => void;
}

const ContractCustomerView: React.FC<IProps> = ({ projectId, orderParams, callback }) => {
    const [form] = Form.useForm();
    const [searchLoading, setSearchLoading] = useState<boolean>(false);
    const [customersOptions, setCustomersOptions] = useState<Array<CustomerModel>>([]);

    useEffect(() => {
        if (orderParams) {
            form.setFieldValue('items', (orderParams.customers ?? []))
            if (customersOptions.length <= 0) {
                let old = orderParams.customers ? orderParams.customers : []
                setCustomersOptions(old.filter(item => item && item.id));
            }
        }
    }, [orderParams])

    const onUpdated = () => {
        let items = form.getFieldValue('items');
        if (items && items.length > 0) {
            callback({ ...orderParams, customers: items });
        }
    }

    const getCustomers = useDebouncedCallback(
        (keyword, dataIndex: any) => {
            let params = {
                "pageIndex": 1,
                "pageSize": 10,
                'name': isNaN(keyword) ? keyword : '',
                'mobile': !isNaN(keyword) ? keyword : '',
                'projectID': projectId,
            }
            setSearchLoading(true);
            setCustomersOptions([])
            fetchCustomers(params).then((res: any) => {
                if (res.code === 0) {
                    if (res.data && res.data.length > 0) {
                        setCustomersOptions([...res.data])
                    } else {
                        onSelectChange(dataIndex, keyword)
                    }
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    const onSelectChange = (dataIndex: any, value: any) => {
        let items = form.getFieldValue('items');
        if (value) {
            let customer = (customersOptions ?? []).find(item => item.id === value);
            if (customer) {
                items[dataIndex] = customer;
            } else {
                let old = items[dataIndex] ?? {};
                items[dataIndex] = { ...old, id: '-1', name: value };

                setCustomersOptions([{ ...old, id: '-1', name: value }])
            }
        } else {
            let old = items[dataIndex] ?? {};
            items[dataIndex] = { ...old, id: undefined, name: undefined };
        }
        form.setFieldValue('items', [...items])
    }

    return (
        <Form
            wrapperCol={{ span: 18 }}
            form={form}
            name="dynamic_form_complex"
            style={{ margin: '24px 0' }}
            autoComplete="off"
            initialValues={{ items: [{}] }}
            onValuesChange={onUpdated}
        >
            <Form.List name="items">
                {(fields, { add, remove }) => (
                    <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                        {fields.map((field) => (
                            <Card
                                size="small"
                                title={`客户 ${field.name + 1}`}
                                key={field.key}
                                extra={
                                    <CloseOutlined
                                        onClick={() => {
                                            remove(field.name);
                                        }}
                                    />
                                }
                            >
                                <Space style={{ width: '100%' }} align="center" size={[32, 12]} wrap>
                                    <Form.Item style={{ marginBottom: 0 }} label="客户姓名" name={[field.name, 'id']}>
                                        <Select
                                            style={{ width: 180 }}
                                            placeholder='搜索客户'
                                            defaultActiveFirstOption={false}
                                            suffixIcon={null}
                                            filterOption={false}
                                            onSearch={(value) => getCustomers(value, field.name)}
                                            notFoundContent={searchLoading ? <Spin /> : null}
                                            options={customersOptions.map(customer => ({ key: customer.id, value: customer.id, label: customer.name, name: customer.name, mobile: customer.mobile }))}
                                            optionRender={(option) => {
                                                return (
                                                    <Space>
                                                        <div style={{ flex: 1 }}>
                                                            <b style={{ wordBreak: 'break-word' }}>{option.data.name}</b>
                                                            <div>
                                                                <Typography.Text style={{ color: '#999', fontSize: 12 }}>
                                                                    {option.data.mobile}
                                                                </Typography.Text>
                                                            </div>
                                                        </div>
                                                    </Space>
                                                )
                                            }}
                                            onChange={(value) => onSelectChange(field.name, value)}
                                            allowClear
                                            showSearch
                                        />
                                    </Form.Item>
                                    <Form.Item style={{ marginBottom: 0 }} label="手机号码" name={[field.name, 'mobile']}>
                                        <Input placeholder="手机号" />
                                    </Form.Item>
                                    <Form.Item style={{ marginBottom: 0 }} label="证件类型" name={[field.name, 'cardType']}>
                                        <Select options={[
                                            { key: 2, label: '身份证', value: '1' },
                                            { key: 1, label: '护照', value: '2' },
                                            { key: 3, label: '军官证', value: '3' },
                                        ]} style={{ width: '180px' }} />
                                    </Form.Item>
                                    <Form.Item style={{ marginBottom: 0 }} label="证件号码" name={[field.name, 'cardId']}>
                                        <Input placeholder="证件号码" />
                                    </Form.Item>
                                </Space>
                            </Card>
                        ))}

                        <Button type="dashed" onClick={() => add()} block>
                            + 添加客户
                        </Button>
                    </div>
                )}
            </Form.List>
        </Form>
    );
};

export default ContractCustomerView;