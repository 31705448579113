import dayjs from "dayjs";

export function phoneIsVaild(number) {
    if (!number || !String(number).trim()) return false;
    let reg = new RegExp(/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/);
    return reg.test(number);
}

export function userAgent() {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //判断是否是 android终端
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isAndroid) {
        return 'android';
    } else if (isIOS) {
        return 'ios';
    } else {
        return 'pc';
    }
}

export function exportCsv(csv, title) {
    const t = dayjs.utc(Date.now()).local().format('YYYY-MM-DD')  // 当前日期
    const filename = `${t + title}.xlsx` // 拼接文件名

    const blob = new Blob([csv], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })  //创建一个新的 Blob 对象
    const url = window.URL.createObjectURL(blob)  //  把一个blob对象转化为一个Blob URL，创建下载链接
    const downloadLink = document.createElement('a')  // 生成一个a标签
    downloadLink.href = url
    downloadLink.download = filename  // // dowload属性指定下载后文件名
    document.body.appendChild(downloadLink) //将a标签添加到body中
    downloadLink.click() // 点击下载
    document.body.removeChild(downloadLink)  // 下载完成后移除元素
    window.URL.revokeObjectURL(url); // 释放掉blob对象
}